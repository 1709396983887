<template>

  <v-sheet  class="fill-height d-flex justify-center"
            :class="[
                { 'align-center' : !element.config.valign || element.config.valign === 'center'  },
                { 'align-baseline' :  element.config.valign === 'top'  },
                { 'align-end' :  element.config.valign === 'bottom'  }
            ]"
            color="transparent"
  >
    <v-divider :style="elementStyle"
               :vertical="element.config.vertical"  />
  </v-sheet>

</template>

<script>

export default {
  name: "viewButton",
  components : {},
  props : {
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    }
  },
  computed : {

    elementStyle() {

      let style = ''
      if ( this.element.config.thickness ) {
        style +=  `border-width: ${this.element.config.thickness || 1}px;`
      }

      if ( this.blockStyle ) {
        style += `border-color : ${this.CONFIG_PARAM_COLOR(
            'styles' ,
            this.blockStyle ,
            'line_color'
        ) } ;`

      } else {
        style += `border-color : ${this.GET_AVALON_COLOR(this.element.config.background_color) } ;`
      }

      return style

    }

  },
  mounted() {
    if ( !this.element.config ) {
      this.element.config = {}
    }
  }
}
</script>

<style scoped>

</style>