<template>

    <div
        class="fill-height d-flex align-center justify-center"
        :class="[
                { 'align-center' :  element.config.valign === 'center'  },
                { 'align-baseline' :  !element.config.valign || element.config.valign === 'top'  },
                { 'align-end' :  element.config.valign === 'bottom'  }
            ]"
        :style="backgroundStyle"
    >

      <div
          :id="'ae_element_' + element.id"
          :style="elementStyle"
          style="width: 100%; padding: 0 !important; margin: 0 !important" >
        <avalon-html-viewer
            :text="element.text"
            :block-style="block.config.style"
            :block-width="block.config.width || 1240"
        />

      </div>



    </div>

</template>

<script>

export default {
  name: "viewButton",
  components : {
  },
  props : {
    element : {
      type : Object,
      default() { return {} }
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    },
  },
  data() {
    return {}
  },
  computed : {
    siteConfig() {
      return this.$store.state.avalon.config
    },
    backgroundStyle() {
      let style = ''

      if ( this.element.config.is_outlined ) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important ;`
        if ( this.element.config.border_style ) {
          style += `border-style : ${this.element.config.border_style } !important ;`
        }
      }

      if ( this.element.config && this.element.config.has_background ) {
        style += `background : ${this.GET_AVALON_COLOR(this.element.config.background_color)};`
      }

      if ( this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      return style
    },

    elementStyle() {
      let style = ''

      if ( !this.element.config ) {
        return ''
      }

      if ( this.element.config.padding_top) {
        style += `padding-top : ${this.element.config.padding_top}px;  `
      }
      if ( this.element.config.padding_bottom) {
        style += `padding-bottom : ${this.element.config.padding_bottom}px;  `
      }
      if ( this.element.config.padding_left) {
        style += `padding-left : ${this.element.config.padding_left}px;  `
      }
      if ( this.element.config.padding_right) {
        style += `padding-right : ${this.element.config.padding_right}px;  `
      }

      return style
    }

  },
  watch : {
    MOBILE_VIEW() {
      setTimeout(()=> {
        this.processTextStyling()
      },1)

    },
    SM() {
      setTimeout(()=> {
        this.processTextStyling()
      },1)
    },
    'siteConfig.fonts' : {
      handler() {
        this.processTextStyling()
        setTimeout(()=>{
          this.$emit('check-overlap')
        },200)

      },
      deep : true
    },
  },
  methods : {

    processTextStyling() {
      this.PROCESS_TEXT_STYLES(`quill_${this.element.id}` , this.block.config.style || 'light_1' ,   true , true )
    },

  },
  mounted() {
    if ( !this.element.config ) {
      this.element.config = {}
    }
    setTimeout(()=>{
      this.$emit('check-overlap')
    },300)
  }
}
</script>

<style scoped>

</style>