<template>
  <div  ref="containerRef"
        :style="`opacity : ${contentReady ? 1 : 0}`"
        style="transition: opacity 0.3s ease; "
  >
    <!-- Payment Plans-->
    <div class="d-flex flex-wrap  pb-4" v-if="course.has_payment_plans">
      <template   v-for="(plan , i ) in paymentPlansFiltered">
        <v-sheet
            :key="i + 'plan'"
            style="border-radius: 100px"
            @click="changePlan(plan.value)"
            class="pa-2 px-4 mb-2 mr-2 pointer"
            v-ripple
            :color="selectedPlan === plan.value ? BLOCK_COLOR_PARAM(element , 'button_bg' , blockStyle ) : 'transparent'"
        >
          <h5 :key="SCALE_COEFFICIENT + 'plan'" :style="buttonStyle(plan.value)">{{  plan.text  }} </h5>
        </v-sheet>


      </template>

    </div>
    <!-- Content design-->
    <template v-if="content && content.length > 0">
      <div v-for="(module , i) in content"
           :key="i"
           :style="`opacity : ${displayContent ? 1 : 0}`"
           style="transition: opacity 0.3s ease;"
      >

        <v-sheet :height="element.config.gutter_vertical / (i === 0 ? 2 : 1)" color="transparent"
                 class="d-flex "
                 :class="[{'align-center' : i > 0}]"
        >
          <v-divider style="width: 100%;"
                     :style="lineStyle"
          />
        </v-sheet>

        <v-row
            :style="getRowStyle"
            no-gutters>
          <v-col
              :style="getColStyle(i)"
              :cols="leftRowColumns"
          >
            <h4 :key="SCALE_COEFFICIENT + 'module'"
                :style="BLOCK_TEXT_CSS(element , blockStyle , 'course-content' , 'module', null, null, DEFAULT_BLOCK_WIDTH)"  >
              {{  module.name  }}
              <br>
            </h4>
          </v-col>
          <v-col
              :style="getColStyle(i)"
              :cols="rightRowColumns"
          >

          <span v-for="(topic, j) in module.topics" :key="`topic_${i}${j}`">

            <h5 :key="SCALE_COEFFICIENT + 'topic'"
                :style="BLOCK_TEXT_CSS(element , blockStyle , 'course-content' , 'topic', null, null, DEFAULT_BLOCK_WIDTH)"
                :class="[{'pt-6' : j>0}]"
                class="mb-4"
            >
                    {{ topic.name }}
            </h5>

              <div >
                <h5 v-for="(component, k) in topic.components" :key="`component_${i}${j}${k}${SCALE_COEFFICIENT}`"
                    class="font-weight-regular d-flex align-center">
                  <v-icon
                      :size="BLOCK_FONT_VARIABLE(element , 'size' , 'course-content' , 'component')*1.2*SCALE_COEFFICIENT"
                      :color="BLOCK_COLOR_PARAM(element , 'course-content_icon' , blockStyle)"
                      class="mr-2"
                  >
                    {{ getComponentIcon(component.entity_type) }}
                  </v-icon>

                  <h5  :style="BLOCK_TEXT_CSS(element , blockStyle , 'course-content' , 'component', null , null , SCALE_COEFFICIENT)" >
                    {{ component.name }}
                  </h5>

                </h5>
              </div>
            </span>


          </v-col>
        </v-row>


      </div>
    </template>
    <div v-else>
      <h5 :key="SCALE_COEFFICIENT + 'plan'"  :style="CONFIG_TEXT_CSS(blockStyle , 'h5' )">
        {{ $t('CourseNoContent') }}
      </h5>
    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementLineEditor",
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {
            gutter_vertical : 40,
            gutter : 40,
            list_proportion : "2-3"
          }
        }
      }
    },
    blockStyle : {
      type : String
    }
  },
  data() {
    return {
      displayContent : true,
      contentReady : false,
      firstLoad : false,
      courseData : {},
      placeholderContent : [
        { name : 'Module 1: Introduction to Design' , topics : [
            { name : 'Chapter 1: What is Design? ' , components : [
                { name : 'Lecture 1: Definition and Scope of Design' },
                { name : 'Lecture 2: Historical Overview of Design' },
                { name : 'Lecture 3: Design as Problem-Solving Process' }
              ]
            },
            { name : 'Chapter 2: Importance of Design in Various Industries? ' , components : [
                { name : 'Lecture 1: Design in Graphic Industry' , type : 'lecture' },
                { name : 'Lecture 2: Design iun Product Design Industry' , type : 'lecture' },
                { name : 'Lecture 3: Design in UX/UI Design Industry' , type : 'lecture' }
              ]
            }
          ]
        },
        { name : 'Module 2: Introduction to Design' , topics : [
            { name : 'Chapter 1: What is Design? ' , components : [
                { name : 'Lecture 1: Definition and Scope of Design' },
                { name : 'Lecture 2: Historical Overview of Design' },
                { name : 'Lecture 3: Design as Problem-Solving Process' }
              ]
            },
            { name : 'Chapter 2: Importance of Design in Various Industries? ' , components : [
                { name : 'Lecture 1: Design in Graphic Industry' },
                { name : 'Lecture 2: Design iun Product Design Industry' },
                { name : 'Lecture 3: Design in UX/UI Design Industry' }
              ]
            }
          ]
        }

      ],
      selectedPlan : null
    }
  },
  computed : {
    paymentPlansFiltered() {
      if ( !this.course ) {
        return []
      }
      if ( !this.course.has_payment_plans ) {
        return []
      }
      if ( !this.course.payment_plans ) {
        return []
      }

      let items = this.COPY(this.course.payment_plans)
      items = items.filter( el => !this.restrictedPlans.includes(el.value))
      return items

    },
    leftRowColumns() {
      if ( this.SM || this.element.config.single_column ) {
        return 12
      }
      return this.element.config.list_proportion === '2-3' ? 4 : 6
    },
    rightRowColumns() {
      if ( this.SM || this.element.config.single_column ) {
        return 12
      }
      return this.element.config.list_proportion === '2-3' ? 8 : 6
    },
    getRowStyle() {
      let style = ''
      let paddingValue = this.element.config.gutter || 0
      if ( paddingValue && !( this.SM || this.SM ) ) {
        style += `margin-left : -${paddingValue/2}px;`
        style += `margin-right : -${paddingValue/2}px;`
      }
      return style
    },
    lineStyle() {
      let style = ''
      if ( this.element.config.thickness  ) {
        style +=  `border-width : ${this.element.config.thickness}px; `
      }
      style += `border-color : ${this.BLOCK_COLOR_PARAM(this.element ,'course-content_line' , this.blockStyle )}`

      return style
    } ,

    restrictedPlans() {
      return this.element.config.restricted_plans || []
    },
    restrictedComponents() {
      return this.element.config.restricted_components || {}
    },
    configCourseId() {
      return this.element.config.course
    },
    course() {
      let checkId = this.$store.state.avalonPage.course ? this.$store.state.avalonPage.course.uuid : null
      if (!this.element.config.course && this.$store.state.avalonPage.course ) {
        return this.$store.state.avalonPage.course
      }
      if ( checkId === this.element.config.course ) {
        return this.$store.state.avalonPage.course
      }
      return   this.courseData
    },
    content() {
      if (!this.course.uuid ) {
        return this.placeholderContent
      }
      let items = this.COPY(this.course.modules)

      if ( !items ) {
        items = []
      }

      if ( this.course.has_payment_plans && this.course.payment_plans && this.selectedPlan  ) {

        this.course.modules.forEach((module , m) => {
          module.topics.forEach((topic, t) => {
            items[m].topics[t].components = items[m].topics[t].components
                .filter(el=> el.payment_plan_id && el.payment_plan_id.includes(this.selectedPlan))
          })
        })

        this.course.modules.forEach((module , m) => {
          items[m].topics = items[m].topics.filter(el=> el.components.length > 0)
        })

        items = items.filter( el => el.topics.length > 0 )

      }

      if ( this.restrictedComponents && this.restrictedComponents[this.selectedPlan] ) {
        items.forEach((module , m) => {
          module.topics.forEach((topic, t) => {
            items[m].topics[t].components = items[m].topics[t].components
                .filter(el=> !(this.restrictedComponents[this.selectedPlan] || [] ).includes(el.uuid) )
          })
        })
      }

      return items
    },
    gridIconsRows() {
      switch (this.element.config.grid_icons_rows) {
        case 2 : return 6
        default : return 12
      }
    },
    gridNumbersRows() {
      switch (this.element.config.grid_numbers_rows) {
        case 1 : return 12
        case 2 : return 6
        case 3 : return 4
        case 4 : return 3
        default : return 6
      }
    }
  },
  watch : {
    paymentPlansFiltered : {
      handler(value) {
        if ( value && value[0] ) {
          this.selectedPlan = value[0].value
        }
      },
      deep : true
    },
    configCourseId() {
      this.handleCourse()
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),
    ...mapActions('courses', ['GET_COURSE_PUBLIC']),

    changePlan(plan) {
      this.selectedPlan = plan;
      this.displayContent = false
      setTimeout(()=> {
        this.$emit('check-overlap')
        this.displayContent = true
      }, 10)

    },

    getColStyle(index) {
      let style = ''
      let paddingValue = this.element.config.gutter || 0
      if ( paddingValue && !(this.SM || this.SM) ) {
        style += `padding-left : ${paddingValue/2}px;`
        style += `padding-right : ${paddingValue/2}px; `
      }
      if ( this.element.config.style === 'grid_numbers'  && index >= this.element.config.grid_columns && this.element.config.gutter_vertical ) {
        style += `padding-top : ${this.element.config.gutter_vertical}px;`
      }

      return style
    },

    buttonStyle(plan) {
      let style = this.BLOCK_TEXT_CSS(this.element , this.blockStyle , 'buttons' , 'text', null , null , this.DEFAULT_BLOCK_WIDTH)
      style = style.slice(13)
      style += `color : ${ this.BLOCK_COLOR_PARAM(this.element , plan === this.selectedPlan ? 'button_text' : 'button_text_secondary'  , this.blockStyle) };`
      return style
    },

    getComponentIcon(type) {
      switch ( type ) {
        case 'lecture'    : return 'mdi-book-open'
        case 'task'       : return 'mdi-content-paste'
        case 'task_video' : return 'mdi-cast-education'
        case 'test'       : return 'mdi-order-bool-ascending-variant'
        case 'task_test'  : return 'mdi-order-bool-ascending-variant'
        case 'video'      : return 'mdi-video'
        case 'file'       : return 'mdi-file'
        default : return 'mdi-bookmark'
      }
    },


    parseNewLine(input) {
      if ( !input ) {
        return ''
      }
      return input.replace(/\n/g,'<br>')
    },
    async handleCourse() {
      this.contentReady = false

      let checkId = this.$store.state.avalonPage.course ? this.$store.state.avalonPage.course.uuid : null

      if (  this.element.config.course && this.element.config.course !== checkId ) {

        let result = await this.GET_COURSE_PUBLIC(this.element.config.course)
        if ( !result ) {
          this.contentReady = true
          return
        }
        if ( result.has_payment_plans && result.payment_plans && result.payment_plans[0]) {
          this.selectedPlan = result.payment_plans[0].value || 'all'
        } else {
          this.selectedPlan = 'all'
        }
        this.courseData = result
        this.contentReady = true
      } else {
        if ( this.course.payment_plans && this.course.payment_plans.length > 0 ) {
          this.selectedPlan = this.course.payment_plans[0].value || 'all'
        }
        this.contentReady = true
      }

      this.$emit('check-overlap')
      setTimeout(()=> {
        this.$emit('check-overlap')
      }, 100)


    }
  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
    this.handleCourse()
  }
}
</script>

<style scoped>

</style>