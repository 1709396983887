<template>

  <!-- Layout Shadow -->
  <v-hover v-model="elementHover" @input="$emit('element-hover' , $event)"  >
    <v-sheet
        @click.stop="selectElement"
        ref="grid"
        :style="layoutStyle + (elementHover && $store.state.avalon.menuOnlyStylesAndFonts && $store.state.avalon.menuNavigation !== 'settings' ? `;box-shadow: inset 0 0 0 4px ${wsATTENTION} !important; cursor : pointer` : '')"
        color="transparent"
        light
        class="fill-height" >

      <view-text
          v-if="element.type === 'text' "
          :element="element"
          :block="block"
          @check-overlap="$emit('check-overlap')"
      />

      <view-image
          v-if="element.type === 'image' "
          :block-style="block.config.style"
          :element="element"

      />

      <view-video
          v-if="element.type === 'video' "
          :element="element"
      />

      <view-rectangle
          v-if="element.type === 'rectangle' "
          :element="element"
      />
      <view-line
          v-if="element.type === 'line' "
          :element="element"
          :block-style="block.config.style"
      />
      <view-button
          v-if="element.type === 'button' || element.type === 'modal'  "
          :element="element"
          :block-style="block.config.style"
          :block="block"
      />
      <view-form
          v-if="element.type === 'form' "
          :element="element"
          :block-style="block.config.style"
          :block="block"
      />
      <view-list
          v-if="element.type === 'list' "
          :id="'ae_element_' + element.id"
          :element="element"
          :block-style="block.config.style"
          :block="block"
      />

      <view-course-content
          v-if="element.type === 'course-content' "
          :id="'ae_element_' + element.id"
          :element="element"
          :block-style="block.config.style"
          @check-overlap="$emit('check-overlap')"
      />

      <div v-if="element.type === 'accordion' "
           :id="'ae_element_' + element.id"
      >
        <view-accordion

            :element="element"
            :block-style="block.config.style"
            @expand="$emit('check-overlap')"
        />
      </div>


    </v-sheet>
  </v-hover>


</template>

<script>
import viewButton from "@/components/AvalonEditor/View/elements/button/viewButton";
import viewRectangle from "@/components/AvalonEditor/View/elements/viewRectangle";
import viewLine from "@/components/AvalonEditor/View/elements/viewLine";
import viewText from "@/components/AvalonEditor/View/elements/viewText";
import viewImage from "@/components/AvalonEditor/View/elements/viewImage";
import viewForm from "@/components/AvalonEditor/View/elements/viewForm";
import viewAccordion from "@/components/AvalonEditor/View/elements/viewAccordion";
import viewVideo from "@/components/AvalonEditor/View/elements/viewVideo";
import viewList from "@/components/AvalonEditor/View/elements/viewList";
import viewCourseContent from "@/components/AvalonEditor/View/elements/viewCourseContent";

export default {
  name: "zeroElement",
  components: {
    viewText,
    viewImage,
    viewButton,
    viewRectangle,
    viewLine,
    viewForm,
    viewAccordion,
    viewVideo,
    viewList,
    viewCourseContent

  },
  props : {
    element : {
      type : Object,
      default() { return {} }
    },
    block : {
      type : Object,
      default() { return { config : {} } }
    }
  },
  data() {
    return {
      elementHover : false
    }
  },
  computed : {
    layoutStyle() {
      let x1 = !(this.SM || this.MOBILE_VIEW) ? this.element.x1 : this.element.smX1
      let x2 = !(this.SM || this.MOBILE_VIEW) ? this.element.x2 : this.element.smX2
      let y1 = !(this.SM || this.MOBILE_VIEW) ? this.element.y1 : this.element.smY1
      let y2 = !(this.SM || this.MOBILE_VIEW) ? this.element.y2 : this.element.smY2
      let zIndex = this.element.z || 10

      return  `grid-area : ${y1 || 1} / ${x1 || 1} / ${(y2 || 2)+1} / ${(x2 || 4)+1}; z-index : ${zIndex};`
    }
  },
  methods : {
    selectElement() {
      if ( !this.$store.state.avalon.menuOnlyStylesAndFonts  && this.$store.state.avalon.menuNavigation !== 'settings' ) {
        return
      }

      this.$store.state.avalon.selectedBlockType = null
      this.$store.state.avalon.menuNavigationStyleTheme = this.block.config.style || 'light_1'
      this.$store.state.avalon.selectedElementType = this.element.type
      this.$store.state.avalon.selectedBlockStyle = this.block.config.style || 'light_1'


      if ( this.$store.state.avalon.menuNavigation === 'styles'  ) {
        this.$store.state.avalon.menuNavigation = 'styles_theme'
        this.$store.state.avalon.menuNavigationReturn = 'styles'
      } else
      if ( this.$store.state.avalon.menuNavigation === 'fonts'  ) {
        this.$store.state.avalon.menuNavigation = 'fonts_edit_element'
        this.$store.state.avalon.menuNavigationReturn = 'fonts'
        this.$store.state.avalon.menuNavigationElement = 'other'
      }


    },
  },
  mounted() {}
}
</script>

<style scoped>

</style>