<template>
  <div>
    <div style="position: relative" class="mx-3">
      <div ref="wrapper" :style="wrapperStyle"  >

        <template v-for="(element,i) in elements">
          <view-zero-element
              @element-hover="$emit('element-hover' , $event)"
              :style="getOverlapPadding(element)"
              @ready="checkElementOverlap(elements[i])"
              @check-overlap="checkElementOverlap(elements[i])"
              v-window-resize="() => checkElementOverlap(elements[i])"
              :ref="'layout_' + element.id"
              :element="element"
              :block="block"
              :key="'layout_' + element.id"
              :active="vdrActive"
          />

        </template>

      </div>

      <!-- Overlap Height Placeholder-->
      <div v-if="overlapHeight" :style="overlapHeight ? `min-height : ${overlapHeight}px` : null" />
    </div>

  </div>
</template>

<script>


import viewZeroElement from "@/components/AvalonEditor/View/elements/viewZeroElement";
import {mapActions, mapState} from "vuex";

export default {
  name: "ViewZero",
  props : {
    block : {
      type : Object,
      default() { return {} }
    },
    elements : {
      type : Array,
      default() { return [] }
    },
    newElementTrigger : {
      type : Number,
      default : 0
    },
    isCertificate : {
      type : Boolean,
      default : false
    }
  },
  components : {
    viewZeroElement
  },
  data() {
    return {

      overlapMatrix : {},

      pauseResize : false,
      selectedElement : null,

      // rows : 10,
      // columns : 24,
      vdrActive : false,

      displayResize : false,
      contentReady : false,

      x1 : 2,
      x2 : 5,
      y1 : 1,
      y2 : 2,

      dragging : false,

      width: 10,
      height: 10,
      x: 0,
      y: 0,

      colors : ['primary lighten-1' , 'red lighten-1' ,'blue lighten-1' ,'green lighten-1' ,'teal lighten-1' ,'pink lighten-1' ],

    }
  },
  computed : {
    ...mapState('avalon',['rowHeightChangeTrigger']),

    totalOverlap() {
      let value = 0
      if (!this.overlapMatrix) {
        return value
      }
      if ( typeof this.overlapMatrix === 'object') {
        Object.keys(this.overlapMatrix).forEach(row => {
          value += this.overlapMatrix[row]
        })
      }
      return value

    },
    rowHeight() {
      let value = 24

      if ( this.EDITOR_FONT_COEFFICIENT(this.block.config.width) !== 1 ) {
        return value * this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
      }

      return value

    },
    gridGapVertical() {
      let coefficient = this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
      if ( this.MOBILE_VIEW || this.SM ) {
        let value =  4
        if ( coefficient !== 1 ) {
          return value * coefficient
        }
        return value
      }
      let value = this.block.config.gridGapVertical || 4

      return value * coefficient
    },
    gridGapHorizontal() {
      let coefficient = this.EDITOR_FONT_COEFFICIENT(this.block.config.width)
      if ( this.MOBILE_VIEW || this.SM ) {
        let value =  4
        if ( coefficient !== 1 ) {
          return value * coefficient
        }
        return value
      }
      let value = this.block.config.gridGapHorizontal || 4

      return value * coefficient
    },
    overlapHeight() {
      let height = 0
      Object.keys(this.overlapMatrix).forEach( row => {
        if ( this.overlapMatrix[row] > 0 ) {
          height += this.overlapMatrix[row]
        }
      })
      return height
    },
    rows() {
      return !(this.SM || this.MOBILE_VIEW) ? (this.avalonBlockConfig(this.block, 'rows') ) : (this.avalonBlockConfig(this.block, 'rows_sm') )
    },
    columns() {
      if ( this.SM || this.MOBILE_VIEW ) {
        return 6
      }
      return this.avalonBlockConfig(this.block, 'columns') || 24
    },
    wrapperStyle() {

      let columnsValue = ''
      for ( let i = 0; i< this.columns ; i++) {
        columnsValue += '1fr '
      }

      let rowsValue = ''
      for ( let j = 0; j< this.rows ; j++) {
        rowsValue += `${this.rowHeight}px `
      }

      let style = '' +
          `position : relative;` +
          `display: grid;` +
          `transition: all 0.3s ease;` +
          `grid-gap: ${this.gridGapVertical}px ${this.gridGapHorizontal}px;` +
          `grid-template-columns : ${columnsValue}  ;` +
          `grid-template-rows : ${rowsValue} ;` +
          `width : 100% ;`

      if ( this.totalOverlap < 0 ) {
        style += `margin-bottom : ${this.totalOverlap}px`
      }

      return style

    },
    blockStyle() {
      let style = '';
      style += 'padding : 25px 50px'
      return style
    }
  },
  watch: {
    SM() {
      this.initOverlapMatrix()
      this.elements.forEach(element => this.checkElementOverlap(element))
    },
    MOBILE_VIEW() {
      this.initOverlapMatrix()
      this.elements.forEach(element => this.checkElementOverlap(element))
    }
  },
  methods : {
    ...mapActions('avalonPage',[]),


    getOverlapPadding(element) {

      let style = '';
      let overlap = 0;

      let y1 = !(this.SM || this.MOBILE_VIEW) ? element.y1 : element.smY1

      Object.keys(this.overlapMatrix).forEach( y => {
        if ( y < y1  ) {
          overlap += this.overlapMatrix[y] || 0
        }

        // It is better not to touch it
        // if ( y < y1 &&  this.overlapMatrix[y] < 0 && this.SM ) {
        //   overlap += this.overlapMatrix[y]
        // }

      })

      if ( overlap ) {
        style = `margin-top : ${overlap}px`
      }

      return style

    },

    checkElementOverlap(element) {

      setTimeout(()=> {
        let domElement = document.getElementById('ae_element_' + element.id )

        let y1 = !(this.SM || this.MOBILE_VIEW)? element.y1 : element.smY1
        let y2 = !(this.SM || this.MOBILE_VIEW)? element.y2 : element.smY2

        if ( domElement ) {
          let height = domElement.getBoundingClientRect().height
          let elementCellsHeight = y2 - y1 + 1
          let cellHeight = (elementCellsHeight * this.rowHeight) + ((elementCellsHeight-1)* this.gridGapVertical )

          let overlapHeight = height - cellHeight

          let oneCellHeight = (this.rowHeight + this.gridGapVertical)
          let overlapHeightAbsolute = overlapHeight%oneCellHeight


          if ( overlapHeight > 0 ) {
            this.overlapMatrix[y2] = overlapHeight  + Math.abs(overlapHeightAbsolute)
            // console.log(' \\/ I want to push content DOWN')
          } else if (overlapHeight < 0 && Math.abs(overlapHeight) > oneCellHeight && ['course-content','accordion'].includes(element.type)   ) {
            // console.log(' /\\ I want to put content UP')
            this.overlapMatrix[y2] = overlapHeight + Math.abs(overlapHeightAbsolute)
          } else {
            // console.log(' == I want to do nothing ')
            this.overlapMatrix[y2] = 0
          }

        } else {
          // console.log('No DOM element detected! ')
        }
      })


    },

    initOverlapMatrix() {
      for (let i = 1; i <= this.rows; i++) {
        this.overlapMatrix[i] = 0
      }
      this.overlapMatrix = this.COPY(this.overlapMatrix)
    },

    initBlock() {

      this.initOverlapMatrix()
      this.contentReady = true

    }
  },

  mounted() {
    this.initBlock()

  },
}
</script>

<style scoped>
</style>