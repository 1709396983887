<template>

  <ws-link
      :href="element.config.link"
      new-window
      :disabled="$store.state.avalon.editorMode"
      :class="[{'pointer' : element.config.link}]"

  >
    <v-sheet
        @click="editorLinkWarning"
        class="fill-height wsRoundedLight d-flex"
        color="transparent"
    >
      <v-sheet
          color="transparent"
          :style="imageStyle"
          class="fill-height"
          transition="xxx"
          width="100%"
      >
      </v-sheet>

    </v-sheet>
  </ws-link>


</template>

<script>

export default {
  name: "viewButton",
  components : {
  },
  props : {
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  computed : {

    iconColor() {
      return this.BLOCK_COLOR_PARAM(this.element , 'image_icon_color' , this.blockStyle) || 'black'
    },

    containCondition() {
      if ( this.element.config ) {
        if ( this.element.config.contain ) {
          return true
        }
      }
      return false
    },

    imageStyle() {
      let style = ''
      if ( this.IS_WESTUDY_ICON(this.element.text) ) {

        style += ` -webkit-mask: url('${this.element.text}') no-repeat ;
                    mask: url('${this.element.text}') no-repeat ${this.element.config[this.DIM_KEY['image_position_x']] ||50}% ${this.element.config[this.DIM_KEY['image_position_y']] || 50}%;
                    mask-size: ${this.element.config.contain ? 'contain' : 'cover'};
                    -webkit-mask-size: ${this.element.config.contain ? 'contain' : 'cover'};
                    position:relative;
                    background-color: ${this.iconColor} !important;
                    `
      } else {
        style +=  `background: url('${this.element.text}');
                 background-size : ${this.element.config.contain ? 'contain' : 'cover'};
                 background-repeat: no-repeat;
                 position:relative;`
      }

      let positionX = this.element.config.image_position_x || this.element.config.image_position_x === 0 ? this.element.config.image_position_x : 50
      let positionY = this.element.config.image_position_y || this.element.config.image_position_y === 0 ? this.element.config.image_position_y : 50

      if ( !(this.MOBILE_VIEW || this.SM) ) {
        style += `background-position : ${positionX }% ${this.element.config.image_position_y || 50}%;`
      } else {
        style += `background-position : ${positionY}% ${this.element.config.image_position_sm_y || 50}%;`
      }

      if ( this.element.config.rounded ) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      style += this.GET_CLIPPING_MASK(this.element.config.mask || null , this.element.config.mask_resize)

      return style
    }

  },
  methods : {
    editorLinkWarning() {
      if ( this.element.config.link && this.$store.state.avalon.editorMode ) {
        this.notify(this.$t('avalon.links_disabled_in_editor') , 'warning')
      }

    }
  },
  mounted() {
    if ( !this.element.config ) {
      this.element.config = {}
    }
  }
}
</script>

<style scoped>

</style>