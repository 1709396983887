<template>

    <v-sheet
        class="fill-height d-flex align-center justify-center"
        :style="elementStyle"
        :color="!element.config.outlined ? GET_AVALON_COLOR(element.config.background_color) : 'transparent'"
    >
    </v-sheet>

</template>

<script>

export default {
  name: "viewButton",
  components : {},
  props : {
    element : {
      type : Object,
      default() { return {} }
    }
  },
  computed : {

    elementStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      style += this.GET_CLIPPING_MASK(this.element.config.mask || null)

      return style
    }

  },
  mounted() {
    if ( !this.element.config ) {
      this.element.config = {}
    }
  }
}
</script>

<style scoped>

</style>