<template>
  <v-sheet  class="fill-height pointer "
            color="transparent"

  >
    <div v-for="(item , index) in element.config.elements" :key="index">

      <v-divider
          v-if="index === 0 && element.config.display_line"
          :style="`border-color : ${BLOCK_COLOR_PARAM(element , `accordion_line` , blockStyle )}`"
      />



      <div @click="expandItem(item)" class="d-flex justify-space-between align-center pointer"
           :style="`padding : ${paddings[element.config.size]} 0; `"
      >

        <h3 :key="SCALE_COEFFICIENT" :style="BLOCK_TEXT_CSS(element , blockStyle , 'accordion' , 'heading' , null , null, DEFAULT_BLOCK_WIDTH)"  >
          {{ item.title }}
        </h3>

        <v-icon
            :color="BLOCK_COLOR_PARAM(element , `accordion_expand` , blockStyle )"
            large> {{  `mdi-${item.expanded ? 'minus' : 'plus' }`  }} </v-icon>

      </div>


      <v-expand-transition>
        <div v-show="item.expanded" >
          <avalon-html-viewer
              :text="item.text"
              :block-style="blockStyle"
              :update-trigger="trigger"
          />
          <div :style="`padding-bottom: ${paddings[element.config.size] }`" />
        </div>


      </v-expand-transition>

      <v-divider
          v-if="element.config.display_line"
          :style="`border-color : ${BLOCK_COLOR_PARAM(element , `accordion_line` , blockStyle )}`"
      />

    </div>

  </v-sheet>
</template>

<script>
export default {
  name: "viewAccordion",
  props : {
    element : {
      type : Object,
      default() { return { config : { elements : []} } }
    },
    blockStyle : {
      type : String
    }
  },
  data() {
    return {

      trigger : 0,
      expandTrigger : 0,
    }
  },
  computed : {
    paddings() {
      return  {
        small  : `${13 * this.SCALE_COEFFICIENT}px`,
        medium : `${21 * this.SCALE_COEFFICIENT}px`,
        large  : `${33 * this.SCALE_COEFFICIENT}px`,
      }
    },
    elementStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      style += this.GET_CLIPPING_MASK(this.element.config.mask || null , this.element.config.mask_resize)

      return style
    }
  },
  methods : {
    titleTextStyle(fontStyle , isHeading = true) {

      let style = ''

      let fontGroup = ['p','h5','h6'].includes(fontStyle) ? 'paragraphs' : 'headings'

      let elementType = isHeading ? 'heading' : 'text'

      style += `color : ${this.CONFIG_PARAM_COLOR('styles' , this.blockStyle , `accordion_${elementType}` ) }; `
      style += `font-family : ${this.CONFIG_PARAM('fonts', fontGroup ,'family')};`
      style += `font-size : ${this.CONFIG_PARAM("fonts" , fontGroup , "size" , fontStyle )}px; `
      style += `font-weight : ${this.CONFIG_PARAM("fonts" , fontGroup , "weight" )} ;`
      style += `text-transform : ${this.CONFIG_PARAM("fonts" , fontGroup , "case" )} ;`
      style += `line-height : ${this.CONFIG_PARAM("fonts" , fontGroup , "lineHeight" )} + '%' ;`
      style += `letter-spacing : ${this.CONFIG_PARAM("fonts" , fontGroup , "spacing")  || 0}px  ;`
      style += `font-style : ${this.CONFIG_PARAM("fonts" , fontGroup , "style")}  ;`

      return style
    },
    expandItem(item) {
      if ( !this.element.config.multiple_expand) {
        this.element.config.elements.forEach( (_,index) => {
          this.element.config.elements[index].expanded = false
        })
      }
      item.expanded = !item.expanded
      this.$emit('expand')
      this.expandTrigger = 0;
      this.emitExpandAction()

    },
    emitExpandAction() {
      setTimeout( () => {
        this.$emit('expand')
        this.expandTrigger =  this.expandTrigger + 1
        if ( this.expandTrigger < 600 ) {
          this.emitExpandAction()
        }
      } , 1 )
    },
    checkExpand() {

      if ( this.element.config.elements.length > 0 ) {
        if ( this.element.config.expand_first ) {
          this.element.config.elements[0].expanded = true
        } else {
          this.element.config.elements[0].expanded = false
        }
      }
    },
  },
  beforeMount() {
    this.checkExpand()
    setTimeout(()=>{
      this.trigger++
    },100)
  },

}
</script>

<style scoped>

</style>