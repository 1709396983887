<template>

  <v-sheet
      class="fill-height wsRoundedLight"
      color="transparent"
  >
    <template v-if="element.config.video">
      <ws-video-viewer
          :url="element.config.video.is_embed ? element.config.video.url : null"
          :file="element.config.video.is_uploaded ?  element.config.video : null"
          full-screen
          :element-id="element.id"
      />

    </template>

  </v-sheet>

</template>

<script>

export default {
  name: "viewButton",
  components : {
  },
  props : {
    element : {
      type : Object,
      default() { return {} }
    }
  },
  computed : {
  },
  mounted() {
    if ( !this.element.config ) {
      this.element.config = {}
    }
  }
}
</script>

<style scoped>

</style>